var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("v-select", {
                        staticClass: "per-page-selector d-inline-block mx-50",
                        attrs: {
                          options: _vm.pages,
                          dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                          clearable: false,
                        },
                        model: {
                          value: _vm.perPage,
                          callback: function ($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-end",
                      },
                      [
                        _c("v-select", {
                          staticClass: "mr-1",
                          staticStyle: { width: "500px" },
                          attrs: {
                            dir: _vm.$store.state.appConfig.isRTL
                              ? "rtl"
                              : "ltr",
                            label: "title",
                            options: ["Todos", "Ativo", "Inativo"],
                            placeholder: "Filtrar por status",
                          },
                        }),
                        _c("b-form-input", {
                          staticClass: "d-inline-block mr-1",
                          attrs: { placeholder: "Pesquisar..." },
                        }),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({
                                  name: "coupon-create",
                                })
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "text-nowrap" }, [
                              _vm._v("Cadastrar"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataPixels,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(pixel_name)",
                fn: function (data) {
                  return [
                    _c(
                      "b-media",
                      { attrs: { "vertical-align": "center" } },
                      [
                        _c(
                          "b-link",
                          {
                            staticClass: "font-weight-bold d-block text-nowrap",
                            attrs: {
                              to: {
                                name: "customer-view",
                                params: { uuid: data.item.uuid },
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(data.item.pixel_name) + " ")]
                        ),
                        _c("small", { staticClass: "text-dark" }, [
                          _vm._v("ID: " + _vm._s(data.item.pixel_id)),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(status)",
                fn: function () {
                  return [
                    _c(
                      "b-badge",
                      {
                        staticClass: "text-capitalize",
                        attrs: { variant: "light-success" },
                      },
                      [_vm._v(" Ativo ")]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(platform)",
                fn: function (data) {
                  return [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: data.item.platform },
                    }),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c("feather-icon", {
                      staticClass: "cursor-pointer cursor",
                      attrs: {
                        id: "customer-" + data.item.uuid + "-edit-icon",
                        icon: "EditIcon",
                        size: "16",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "customer-edit",
                            params: { uuid: data.item.uuid },
                          })
                        },
                      },
                    }),
                    _c("b-tooltip", {
                      staticClass: "cursor-pointer",
                      attrs: {
                        title: "Editar dados",
                        target: "customer-" + data.item.uuid + "-edit-icon",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "mx-2 mb-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-start",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Exibindo 1 de 2 de 10 resultados"),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-end",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c("b-pagination", {
                        staticClass: "mb-0 mt-1 mt-sm-0",
                        attrs: {
                          "total-rows": _vm.totalUsers,
                          "per-page": 2,
                          "first-number": "",
                          "last-number": "",
                          "prev-class": "prev-item",
                          "next-class": "next-item",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prev-text",
                            fn: function () {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronLeftIcon",
                                    size: "18",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "next-text",
                            fn: function () {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronRightIcon",
                                    size: "18",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }