<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              :options="pages"
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="['Todos', 'Ativo', 'Inativo']"
                style="width: 500px;"
                placeholder="Filtrar por status"
                class="mr-1"
              />
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
              />
              <b-button
                variant="primary"
                @click="$router.push({ name: 'coupon-create' })"
              >
                <span class="text-nowrap">Cadastrar</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        sticky-header
        class="position-relative"
        :items="dataPixels"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(pixel_name)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'customer-view', params: { uuid: data.item.uuid } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.pixel_name }}
            </b-link>
            <small class="text-dark">ID: {{ data.item.pixel_id }}</small>
          </b-media>
        </template>
        <template #cell(status)="" class="text-center">
          <b-badge variant="light-success" class="text-capitalize">
            Ativo
          </b-badge>
        </template>
        <template #cell(platform)="data" class="text-center">
          <img :src="data.item.platform" class="img-fluid">
        </template>

        <template #cell(actions)="data">
          <feather-icon
            :id="`customer-${data.item.uuid}-edit-icon`"
            icon="EditIcon"
            class="cursor-pointer cursor"
            size="16"
            @click="
              $router.push({
                name: 'customer-edit',
                params: { uuid: data.item.uuid },
              })
            "
          />
          <b-tooltip
            title="Editar dados"
            class="cursor-pointer"
            :target="`customer-${data.item.uuid}-edit-icon`"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo 1 de 2 de 10 resultados</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="2"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BPagination, BTooltip, BMedia, BLink,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { avatarText } from '@core/utils/filter';

export default {
  components: {
    BFormInput,
    BLink,
    BCard,
    BRow,
    BCol,
    BMedia,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BTooltip,

    vSelect,
  },
  setup() {
    return { avatarText };
  },
  data() {
    return {
      currentPage: 1,
      totalUsers: 10,
      perPage: 50,
      pages: [
        {
          title: '50',
          value: 50,
        },
        {
          title: '100',
          value: 100,
        },
      ],
      dataPixels: [
        {
          id: 1,
          uuid: 1,
          pixel_name: 'Pixel Loja 1',
          pixel_id: '34564534543',
          platform: 'https://somosyampi.github.io/ecommerce-icons/svg/facebook.svg',
          status: 1,
        },
      ],
      tableColumns: [
        {
          key: 'pixel_name',
          label: 'Pixel',
        },
        {
          key: 'status',
          label: 'Status',
          class: 'text-center',
          thStyle: 'width: 100px',
        },
        {
          key: 'platform',
          label: 'Plataforma',
          class: 'text-center',
          thStyle: 'width: 140px',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-center',
          thStyle: 'width: 100px',
        },
      ],
    };
  },
};
</script>

<style lang='scss' scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang='scss'>
@import '@core/scss/vue/libs/vue-select.scss'
</style>
